import React, { useState, useEffect } from "react";
import { api } from "../../Api";
import DefaultLayout from "../../layout/DefaultLayout";

interface Appointment {
  id: number;
  doctor_id: number;
  user_id: number;
  name: string;
  email: string;
  phone: string;
  day: string;
  created_at: string;
  updated_at: string;
  is_free: number;
  baby_id: number | null;
  doctor: {
    id: number;
    name: string;
    description: string;
    username: string;
    photo: string;
    degree: string;
    examination_price: string;
    special_examination_price: string;
    way_of_waiting: string;
    specialization_id: number;
    position_id: number;
    gouvernorat_id: number;
    num_of_free_cases: number;
    number_of_used_cases: number;
    cases_updated_at: string;
  };
  user: {
    id: number;
    name: string;
    phone: string;
    email: string;
    email_verified_at: string | null;
    is_email_verified: number;
    num_of_free_bookings: number;
    number_of_used_cases: number;
    cases_updated_at: string;
  };
  baby: null;
}

interface User {
  id: number;
  name: string;
  phone: string;
  email: string;
  email_verified_at: string | null;
  is_email_verified: number;
  num_of_free_bookings: number;
  number_of_used_cases: number;
  cases_updated_at: string;
}

const AppointmentManager: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [user, setUser] = useState<User | null>(null);

  // Fetch user data from the dedicated endpoint
  const fetchUser = async () => {
    try {
      const response = await api.get("https://api.nadymama.com/api/users/user");
      setUser(response.data.data);
    } catch (error) {
      console.error("خطأ في جلب بيانات المستخدم:", error);
    }
  };

  // Fetch appointments data
  const fetchAppointments = async () => {
    try {
      const response = await api.get("https://api.nadymama.com/api/users/appointments");
      setAppointments(response.data.data);
    } catch (error) {
      console.error("خطأ في جلب بيانات المواعيد:", error);
    }
  };

  useEffect(() => {
    fetchUser(); // Fetch user data
    fetchAppointments(); // Fetch appointments data
  }, []);

  return (
    <DefaultLayout>
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-12 text-center text-blue-600">حجوزاتي</h1>

        {/* User Information Section */}
        {user && (
          <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-12">
            <h2 className="text-2xl font-semibold mb-6">معلومات المستخدم</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div>
                <p className="text-gray-700 font-bold">الاسم:</p>
                <p className="text-gray-600">{user.name}</p>
              </div>
              <div>
                <p className="text-gray-700 font-bold">البريد الإلكتروني:</p>
                <p className="text-gray-600">{user.email}</p>
              </div>
              <div>
                <p className="text-gray-700 font-bold">الهاتف:</p>
                <p className="text-gray-600">{user.phone}</p>
              </div>
              <div>
                <p className="text-gray-700 font-bold">عدد الحالات المجانية المتبقية:</p>
                <p className="text-gray-600">{user.num_of_free_bookings}</p>
              </div>
              <div>
                <p className="text-gray-700 font-bold">عدد الحالات المستخدمة:</p>
                <p className="text-gray-600">{user.number_of_used_cases}</p>
              </div>
            </div>
          </div>
        )}

        {/* List of Appointments */}
        <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
          <h2 className="text-2xl font-semibold mb-6">قائمة حجوزاتي</h2>
          {appointments.length === 0 ? (
            <p className="text-center text-gray-500 py-4">لا يوجد مواعيد مسجلة حالياً</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {appointments.map((appointment) => (
                <li key={appointment.id} className="py-6">
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="text-xl font-semibold">{appointment.name}</h3>
                      <p className="text-gray-600">الطبيب: {appointment.doctor.name}</p>
                      <p className="text-gray-600">التاريخ: {new Date(appointment.day).toLocaleDateString()}</p>
                      <p className="text-gray-600">الوقت: {new Date(appointment.day).toLocaleTimeString()}</p>
                      <p className="text-gray-600">الحالة: {appointment.is_free === 0 ? "مدفوعة" : "مجانية"}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AppointmentManager;