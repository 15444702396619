import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useParams } from "react-router-dom";
import DefaultLayout from "../../layout/DefaultLayout";
import { api } from "../../Api";

type Position = {
    name: string;
};

interface Doctor {
    id: number;
    name: string;
    description: string;
    username: string;
    password: string;
    photo: string;
    degree: string;
    examination_price: string;
    special_examination_price: string;
    way_of_waiting: string;
    created_at: string;
    updated_at: string;
    specialization_id: number;
    num_of_free_cases: number;
    position: Position;
    phones: [
        {
            phone: string;
        }
    ];
}

interface User {
    id: number;
    num_of_free_bookings: number;
}

interface Baby {
    id: number;
    name: string;
    date_of_birth: string;
    gender: string;
    age_in_days: number;
}

const BookingComponent: React.FC = () => {
    const [doctor, setDoctor] = useState<Doctor | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [babies, setBabies] = useState<Baby[]>([]);
    const [selectedBabyId, setSelectedBabyId] = useState<number | null>(null);

    const fetchUserData = async () => {
        try {
            const response = await api.get("https://api.nadymama.com/api/users/user");
            setUser(response.data.data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const fetchBabies = async () => {
        try {
            const response = await api.get("https://api.nadymama.com/api/users/babies");
            setBabies(response.data);
        } catch (error) {
            console.error("Error fetching babies:", error);
        }
    };

    useEffect(() => {
        fetchUserData();
        fetchBabies();
    }, []);

    const getDayNameInArabic = (dayIndex: number): string => {
        const dayNames = [
            'الأحد',  // Sunday
            'الإثنين', // Monday
            'الثلاثاء', // Tuesday
            'الأربعاء', // Wednesday
            'الخميس', // Thursday
            'الجمعة', // Friday
            'السبت',   // Saturday
        ];
        return dayNames[dayIndex];
    };

    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const daysArray = [];
    let currentDate = new Date(today);

    // Start from the next day
    currentDate.setDate(currentDate.getDate() + 1);

    while (daysArray.length < 20) {
        // Skip Thursday (4) and Friday (5)
        if (currentDate.getDay() !== 4 && currentDate.getDay() !== 5) {
            daysArray.push({
                date: formatDate(currentDate),
                dayName: getDayNameInArabic(currentDate.getDay()),
            });
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
    });

    const [bookingSuccess, setBookingSuccess] = useState(false);
    const [showBookingPopUp, setShowBookingPopUp] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        fetch("https://api.nadymama.com/api/users/doctor/" + id)
            .then((response) => response.json())
            .then((data) => setDoctor(data))
            .catch((error) => console.error("Error fetching doctor data:", error));
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleBooking = () => {
        // Handle booking logic here
        setBookingSuccess(true);
    };

    const closeSuccessPopup = () => {
        setBookingSuccess(false);
    };

    const [day, setDay] = useState<any>();
    const [daySelected, setDaySelected] = useState<any>();

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDay(event.target.value);
        setDaySelected(event.target.value);
    };

    const handleContinueBooking = () => {
        setShowBookingPopUp(true);
    };

    const handleBook = async () => {
        try {
            const bookingData = {
                doctor_id: id,
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                day: day,
                baby_id: doctor?.specialization_id === 1 ? selectedBabyId : null, // Include baby_id if specialization_id is 1
            };

            const response = await api.post('https://api.nadymama.com/api/users/appointments/book', bookingData);
            setShowBookingPopUp(false);
            setBookingSuccess(true);
            console.log('Booking successful:', response.data);
        } catch (error) {
            console.error('Error booking:', error);
        }
    };

    return (
        <DefaultLayout>
            {showBookingPopUp && (
                <>
                    <div className="hide-content" onClick={() => { setShowBookingPopUp(false); }}></div>
                    <div className="booking-popup">
                        <h2>بيانات الحجز</h2>
                        <div className="input-group">
                            <input type="text" name="name" id="name" value={formData.name} required onChange={handleChange} placeholder="الاسم" />
                        </div>
                        <div className="input-group">
                            <input type="text" name="email" id="email" value={formData.email} required onChange={handleChange} placeholder="البريد الالكتروني" />
                        </div>
                        <div className="input-group">
                            <input type="text" name="phone" id="phone" value={formData.phone} required onChange={handleChange} placeholder="رقم الهاتف" />
                        </div>
                        {doctor?.specialization_id === 1 && (
                            <div className="input-group">
                                {babies.length === 0 ? (
                                    <div>
                                        <p style={{color: 'red', marginBottom: 10, textAlign: 'center'}}>يجب إضافة أطفال أولاً للحجز لهم.</p><Link to={'/my-babies'} style={{backgroundColor: 'rgb(219, 219, 219)', color: '#020046', padding: '10px 15px', border: 'medium', borderRadius: 5, marginBottom: 26, margin: '0 auto 26px', display: 'block', width: 'max-content'}}>إضافة طفل الآن</Link></div>
                                ) : (
                                    <>
                                        <label htmlFor="baby" style={{ display: 'block', marginBottom: 10 }}>اختر الطفل</label>
                                        <select
                                            id="baby"
                                            value={selectedBabyId || ""}
                                            onChange={(e) => setSelectedBabyId(Number(e.target.value))}
                                        >
                                            <option value="">اختر طفلاً</option>
                                            {babies.map((baby) => (
                                                <option key={baby.id} value={baby.id}>
                                                    {baby.name}
                                                </option>
                                            ))}
                                        </select>
                                    </>
                                )}
                            </div>
                        )}        
                        <button className="book submit" onClick={handleBook}>حجز</button>
                    </div>
                </>
            )}
            {bookingSuccess && (
                <>
                    <div className="hide-content" onClick={() => { setBookingSuccess(false); }}></div>
                    <div className="success-booking-popup">
                        <h1 style={{ fontSize: "21px", textAlign: "center" }}>تم الحجز بنجاح </h1>
                        <svg style={{ width: "80px", height: "80px", padding: "8px", border: "2px solid #8dc645", borderRadius: "50%", margin: "16px auto", display: "block" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#8dc645" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l5 5l10 -10" />
                        </svg>
                        <button className="close-success submit" onClick={() => { setBookingSuccess(false); }}>اغلاق</button>
                    </div>
                </>
            )}
            <section className="doctor">
                <div className="container">
                    <div className="card_wrapper">
                        <div className="image_wrapper">
                            <div className="img">
                                <img src={'https://api.nadymama.com/public/storage/' + doctor?.photo} alt="" />
                                <div className="details">
                                    <h2 className="name">
                                        {doctor?.name}
                                    </h2>
                                    <p>
                                        {doctor?.position?.name}
                                    </p>
                                    <p>
                                        {doctor?.degree}
                                    </p>
                                </div>
                            </div>
                            <button className="comfirm-booking" style={{ opacity: !day ? .5 : 1 }} disabled={!day} onClick={() => setShowBookingPopUp(true)}>اتمام الحجز</button>
                        </div>
                        <div className="text_wrapper">
                            <p className="desc">
                                {doctor?.description}
                            </p>
                            <p className="price">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-coins" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#5f6264" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" />
                                    <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" />
                                    <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" />
                                    <path d="M3 6v10c0 .888 .772 1.45 2 2" />
                                    <path d="M3 11c0 .888 .772 1.45 2 2" />
                                </svg>
                                سعر الكشف: {doctor?.examination_price} جنيه | <span>سعر ماما: {doctor?.special_examination_price} جنيه</span>
                            </p>
                            <p className="price">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-hour-3" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#5f6264" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                    <path d="M12 12h3.5" />
                                    <path d="M12 7v5" />
                                </svg>
                                مدة الانتظار:
                                {" " + doctor?.way_of_waiting}
                            </p>
                            {
                                (user?.num_of_free_bookings && user?.num_of_free_bookings > 0) ? (
                                    <p className="price">
                                        الكشوفات المجانية المتبقية
                                        {": " + (doctor?.num_of_free_cases || 'لا يوجد')}
                                    </p>
                                ) : (
                                    ''
                                )
                            }
                            <p className="phone">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-call" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#5f6264" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    <path d="M15 7a2 2 0 0 1 2 2" />
                                    <path d="M15 3a6 6 0 0 1 6 6" />
                                </svg>
                                {
                                    doctor?.phones.map((phone, index) => (
                                        <span key={index}>{phone['phone'] + ((index != doctor.phones.length - 1) ? " - " : '')}</span>
                                    ))
                                }
                            </p>
                            <div className="host">
                                <span>حجز موعد مع الطبيب</span>
                                بمجرد قيامك بالحجر على موقع نادي ماما برجاء التوجه لعيادة الطبيب في نفس يوم الحجز من الأسبوع التالي و الدخول بأولوية الحضور للعيادة
                                <div className="slots">
                                    {daysArray.map((day, index) => (
                                        <div className="slot" key={index}>
                                            <input
                                                type="radio"
                                                name="day"
                                                value={day.date}
                                                checked={daySelected === day.date}
                                                onChange={handleRadioChange}
                                                id={`slot_time_${index}`}
                                            />
                                            <label htmlFor={`slot_time_${index}`} style={{ minWidth: 175, position: 'relative', display: "block" }}>
                                                {`${day.dayName} ${day.date}`}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </DefaultLayout>
    );
};

export default BookingComponent;